<template>
  <div>
    <v-card flat rounded="0">
      <v-container>
        <div class="d-flex">
          <v-btn @click="$router.go(-1)" class="my-auto" icon>
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
          <div class="my-auto ml-2">
            Fill Details
          </div>
        </div>
      </v-container>
    </v-card>
    <div v-if="pincodeConfirmed">
      <editDetails
        :pincode="pincodeData[0]"
        :vehicleSelected="vehicleSelected"
      ></editDetails>
    </div>
    <v-dialog persistent v-model="dialog" max-width="300">
      <v-card cols="12" style="border-radius:0px">
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-title class="pa-0 px-4 py-2">
          <p class="ma-0 pa-0 header-mx">Service Location check!</p>
        </v-card-title>

        <v-card-text class="px-4 pa-0 pb-5">
          <p class="caption ma-0 pa-0">
            We are servicable in only certain areas. Please provide your PIN
            Code to begin.
          </p>
        </v-card-text>
        <v-col>
          <v-form v-model="isFormValid">
            <v-text-field
              @keyup.enter="checkPincode"
              v-model="pincode"
              :rules="validatePincode"
              autofocus
              counter
              hide-details="auto"
              label="Pincode"
              maxlength="6"
              min="0"
              outlined
              type="text"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col class="mx-auto text-right">
          <!-- <v-btn color="green darken-1" text @click="fetchLocation">
            Use my Location
          </v-btn> -->

          <v-btn
            color="green darken-1"
            :disabled="!isFormValid"
            text
            @click="checkPincode"
          >
            Check Pincode
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="servicable" max-width="290">
      <v-card v-if="pincodeData" class="pa-4">
        <div>
          <p class="caption">
            Congratulations, service is available for
            <span class="color-p"
              >{{ pincodeData[0].pincode }}. {{ pincodeData[0].district }},
              {{ pincodeData[0].cityData.name }}</span
            >
          </p>
        </div>

        <v-col class="mx-auto text-right pa-0 ma-0">
          <!-- <v-btn color="green darken-1" text @click="fetchLocation">
            Use my Location
          </v-btn> -->
          <!-- <v-btn color="green darken-1" text @click="servicable = false">
            Check Another Pin
          </v-btn> -->
          <v-btn color="green darken-1" text @click="confirmAddress">
            Confirm
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="notServicable" max-width="290">
      <v-card class="pa-2 py-4">
        <v-alert dense outlined type="error">
          <p class="caption">
            {{ errorMessage }}
          </p>
        </v-alert>

        <v-col class="mx-auto text-right ma-0 pa-0">
          <!-- <v-btn color="green darken-1" text @click="fetchLocation">
            Use my Location
          </v-btn> -->

          <v-btn color="green darken-1" text @click="notServicable = false">
            Check Another Pin
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import editDetails from "./EditDetails.vue";

export default {
  data: () => ({
    isLoading: null,
    currentLocation: "",
    address: "",
    dialog: true,
    isFormValid: false,
    pincode: null,
    validatePincode: [
      (value) => !!value || "Pincode is required.",
      // value => (value && value.length >= 5) || 'Min 5 characters',
      (value) =>
        (value > 0 && value.length === 6) || "Please enter a valid Pincode",
    ],
    servicable: false,
    pincodeData: null,
    notServicable: false,
    errorMessage: "",
    pincodeConfirmed: false,
    vehicleSelected: null,
  }),
  components: {
    editDetails,
  },
  created() {
    if (!localStorage.getItem("selectionData")) {
      alert("please select vehicle first");
      this.$router.push("/finance");
    } else {
      this.vehicleSelected = JSON.parse(localStorage.getItem("selectionData"));
    }
  },
  methods: {
    confirmAddress() {
      this.dialog = false;
      this.pincodeConfirmed = true;
      this.servicable = false;
      let leadSource = localStorage.getItem("source") || "Organic";
      var eventName = leadSource
        ? "confirm-pincode-" + leadSource
        : "confirm-pincode-organic";
      window.fbq("track", eventName);
      this.$gtag.event(eventName, {
        event_category: "click-event",
        event_label: "page view",
        value: 1,
      });
    },
    fetchLocation() {
      this.$getLocation().then((x) => {
        console.log(x);
        this.currentLocation = x;
        this.getStreetAddressFrom(x.lat, x.lng);
      });
    },
    // async getStreetAddressFrom(lat, long) {
    //   try {
    //     var { data } = await axios.get(
    //       "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
    //         lat +
    //         "," +
    //         long +
    //         "&key=AIzaSyC-eQsx6q7hHySLEqtnyKqUv1lTmB5pYLw"
    //     );
    //     if (data.error_message) {
    //       console.log(data.error_message);
    //     } else {
    //       this.address = data.results[0].formatted_address;
    //       console.log(this.address)
    //       console.log(data)
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // },
    async checkPincode() {
      try {
        this.isLoading = true;
        const { data } = await this.$api.HTTP2.get(
          `finance/checkpincode/${this.pincode}`
        );
        console.log(data);
        if (data.err === 1) {
          this.servicable = false;
          this.notServicable = true;
          this.servicableAlert = false;
          this.error = true;
          this.errorMessage = data.message;
          this.pincodeData = null;
        } else {
          this.pincodeData = data;
          this.servicable = true;
          this.servicableAlert = true;
          this.error = false;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
